<template>
  <el-dialog
    title="新增节点"
    :visible.sync="dialogVisible3"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        label-width="100px"
        :rules="formRules"
      >
        <el-form-item
          label="上级类别"
          prop="areaCode"
          style="width: 100% !important"
        >
          <el-select v-model="form.parentId" style="width: 200px">
            <el-option
              v-for="(item, index) in types"
              :key="index"
              :value="item.id"
              :label="item.label"
            ></el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="子类名称">
          <el-input
            placeholder="请填写子类名称"
            min="1"
            v-model="form.secondName"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible3 = false">取 消</el-button>
      <el-button type="primary" @click="addBtn()" :disabled="is_click">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addNode, nodeList } from "@/api/CollectivePurchase";
export default {
  name: "NowAddNode",

  data() {
    return {
      dialogVisible3: true,
      form: {
        parentId: "",
        secondName: "",
      },
      formRules: {},
      keyForm: 1,
      types: {},
      addList: [],
      is_click: false, //防止暴力点击
    };
  },

  mounted() {
    this.nodeList();
  },

  methods: {
    back() {
      this.$emit("closeDialog3", false);
    },
    handleClose() {
      this.$emit("closeDialog3", false);
    },

    async addInit(obj) {
      console.log(obj, "obj");
      this.form.parentId = obj.id;
      this.addList = obj;
    },
    async nodeList() {
      let params = "first_crop_type";
      let res = await nodeList(params);
      this.types = res.data;
    },
    async addBtn() {
      this.is_click = true;
      let parentNameIndex = this.types.findIndex(
        (item) => item.id == this.form.parentId
      );
      let params = {
        parentId: this.form.parentId,
        parentName: this.types[parentNameIndex].label,
        secondName: this.form.secondName,
      };
      console.log(params);
      let res = await addNode(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.info(res.msg);
      }
      this.$emit("closeDialog3", false);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
